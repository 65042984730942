<template>
  <l-geo-json :geojson="geojson"></l-geo-json>
</template>

<script>
export default {
  props: ['url'],
  data() {
    return {
      geojson: null,
    };
  },
  async created() {
    this.axios.get(this.url)
      .then(res => {
        this.geojson = res.data;
      }).catch(err => {
        console.log(err);
      });
  }
}
</script>
