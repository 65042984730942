<template>
  <div class="dropdown-select state-default">
    <div tabindex="0" class="my-select">
      <div class="select" @click="showList()">{{ this.$store.getters.getLocationProvinceChoiceName }}</div>
      <div class="dropdown-items" v-if="this.$store.getters['getLocationProvinceShow']">
        <div v-for="item in this.$store.getters['getLocationProvince']" :key="item.id">
          <div @click="selectItem(item.id, item.name)">
            <span class="selected">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      show: false,
      name: "Chọn thành phố",
    }
  },
  async created() {
    this.$store.dispatch('setLocationProvince')
  },
  methods: {
    showList() {
      this.$store.dispatch('setLocationProvinceShow', !this.$store.getters['getLocationProvinceShow'])
    },
    selectItem(item, name) {
      this.$store.dispatch('setLocationProvinceShow', false)
      this.$store.dispatch('setLocationDistrictShow', false)
      this.name = name;
      this.$store.dispatch('setLocationProvinceChoiceName', name)
      this.$store.dispatch("setLocationProvinceChoice", item);
      this.$store.dispatch('setLocationDistrictChoiceName', 'Chọn quận')
      this.$store.dispatch("setLocationDistrictShow", false);
    }
  }

}

</script>

<style>
</style>
