<template>
  <div class="dropdown-select city-default">
    <div tabindex="0" class="my-select">
      <div class="select" @click="showList()">{{ this.$store.getters.getLocationDistrictChoiceName }}</div>
      <div class="dropdown-items" v-if="this.$store.getters.getLocationDistrictShow">
        <div v-for="item in this.$store.getters['getLocationDistrict']" :key="item.id">
          <span class="selected" @click="selectItem(item)">{{ item.properties.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      show: false,
      name: "Tất cả quận"
    }
  },
  methods: {
    showList() {
      this.$store.dispatch('getLocationDistrictShow', !this.$store.getters['getLocationDistrictShow'])
      console.log('getLocationDistrictShow', this.$store.getters['getLocationDistrictShow'])
    },
    selectItem(item) {
      this.showList()
      this.$store.dispatch('setLocationDistrictShow', false)
      this.$store.dispatch('setLocationDistrictChoiceName', item.properties.name)
      this.$store.dispatch("setLocationDistrictChoice", item);
      console.log(item);
    }
  }
}
</script>

<style>
</style>
