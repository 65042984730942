<template>
  <div class="city-results">
    <div class="city-list">
      <div class="city-item">
        <div class="city-name">Tp. Hồ Chí Minh</div>
        <div class="city-info">
          <div class="price">40.5<span class="symbol">tr/m2</span></div>
          <div class="deal">+ 25.50%
            <span class="icon-trend-up">
              <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 1L10.375 8.125L6.625 4.375L1 10" stroke="#F2BA2A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path d="M13 1H17.5V5.5" stroke="#F2BA2A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </svg>
            </span>
          </div>
          <div><button class="btn btn-secondary">Thống kê giao dịch <i class="fal fa-angle-right"></i></button></div>
        </div>
      </div>
      <div class="city-item">
        <div class="city-name">Hà Nội</div>
        <div class="city-info">
          <div class="price">40.5<span class="symbol">tr/m2</span></div>
          <div class="deal">+ 25.50%
            <span class="icon-trend-up">
              <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 1L10.375 8.125L6.625 4.375L1 10" stroke="#F2BA2A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path d="M13 1H17.5V5.5" stroke="#F2BA2A" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </svg>
            </span>
          </div>
          <div><button class="btn btn-secondary">Thống kê giao dịch <i class="fal fa-angle-right"></i></button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
