import Vue from 'vue'

import BtnLocation from '../components/button/BtnLocation.vue'
import MapTitleLayer from '../components/map/MapTitleLayer.vue'
import MapGeoJson from '../components/map/MapGeoJson.vue'
import MapPointHouse from '../components/map/MapPointHouse.vue'
import ListProvide from '../components/dropdown/ListProvide.vue'
import ListCity from '../components/dropdown/ListCity.vue'
import ListArea from '../components/dropdown/ListArea.vue'
import MyLocation from '../components/button/MyLocation.vue'
import NavMain from '../components/nav/NavMain.vue'
import HouseCity from '../components/list/HouseCity.vue'

Vue.component('BtnLocation', BtnLocation)
Vue.component('MapTitleLayer', MapTitleLayer)
Vue.component('MapGeoJson', MapGeoJson)
Vue.component('MapPointHouse', MapPointHouse)
Vue.component('ListProvide', ListProvide)
Vue.component('ListCity', ListCity)
Vue.component('ListArea', ListArea)
Vue.component('MyLocation', MyLocation)
Vue.component('NavMain', NavMain)
Vue.component('HouseCity', HouseCity)
