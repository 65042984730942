import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/app.scss'

import "./plugins/leaflet";
import "./plugins/bootstrap";
import "./plugins/geolocation";
import "./plugins/axios";

import "./plugins/useCom";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
