<template>
  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
</template>

<script>
export default {
  data: () => ({
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  }),
}
</script>

<style>
</style>
