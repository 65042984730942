const Map = {
  name: "map",
  state: () => ({
    location: [10.77963, 106.69901],
    myLocation: [],
    zoom: 13,
    geoJson: [],
  }),
  getters: {
    getMapLocation: state => state.location,
    getMapMyLocation: state => state.myLocation,
    getMapLocationLat: state => state.location.lat,
    getMapLocationLng: state => state.location.lng,
    getMapZoom: state => state.zoom,
    getMapGeoJson: state => state.geoJson,
  },
  mutations: {
    setMapLocation: (state, payload) => state.location = payload,
    setMapMyLocation: (state, payload) => state.myLocation = payload,
    setMapZoom: (state, payload) => state.zoom = payload,
    setMapGeoJson: (state, payload) => state.geoJson = payload,
  },
  actions: {
    setMapLocation: ({ commit }, payload) => {
      commit('setMapLocation', payload)
      commit('setMapMyLocation', payload)
    },
    setMapMyLocation: ({ commit }, payload) => commit('setMapMyLocation', payload),
    setMapZoom: ({ commit }, payload) => commit('setMapZoom', payload),
    setMapGeoJson: async ({ commit }, url) => {
      console.log('setMapGeoJson', url)
      Vue.axios.get(url)
        .then(res => commit('setMapGeoJson', res.data))
        .catch(err => console.log(err))
    }
  },
}

export default Map;
