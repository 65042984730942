import Vue from 'vue';

import {
  LMap, LTileLayer, LMarker, LGeoJson, LPopup, LCircle, LIcon, LControlZoom, LControl
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-popup', LPopup);
Vue.component('l-circle', LCircle);
Vue.component('l-icon', LIcon);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-control', LControl);
