<template>
  <div>
    <!-- <nav-main /> -->
    <div class="map-screen">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavMain from './components/nav/NavMain.vue';

export default {
  components: { NavMain },
  mounted() {
    (this.$store.getters['getMapGeoJson']) ? '' : this.$store.dispatch("map/setMapGeoJson", "https://gooodnha.com/api/states/geo_json");
  }
}
</script>

<style lang="less" >
</style>
