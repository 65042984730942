<template>
  <div>
    <l-control position="topright">
      <b-button variant="outline-info" class="mb-2" @click="clickHandler">
        <b-icon icon="geo-alt" aria-label="Help"></b-icon>
      </b-button>
    </l-control>
  </div>
</template>

<script>
export default {
  methods: {
    clickHandler() {
      this.$getLocation()
        .then(coordinates => {
          console.log(coordinates.lat, coordinates.lng);
          this.$store.dispatch(
            "setMapMyLocation",
            { lat: coordinates.lat, lng: coordinates.lng },
            { root: true }
          );
        }).catch(error => {
          alert('Please set permission to access your location');
        });
    }
  }
}
</script>

<style>
</style>
