<template>
  <div class="map-filter-location">
    <b-button class="btnLocation" @click="clickHandler">
      <b-icon icon="cursor" font-scale="1.5" style="color: #11506D"></b-icon>
    </b-button>
    <b-modal id="modal-1" title="get location" v-show="errorGetLocation">
      <p class="my-4">Please set permission to access your location</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorGetLocation: false,
    }
  },
  methods: {
    clickHandler() {
      this.$getLocation()
        .then(coordinates => {
          console.log(coordinates.lat, coordinates.lng);
          this.$store.dispatch("setMapLocation",
            { lat: coordinates.lat, lng: coordinates.lng },
            { root: true }
          );
        }).catch(e => {
          // alert('Please set permission to access your location');
          this.errorGetLocation = true;
        });
    }
  }
}
</script>

<style scoped>
.btnLocation {
  border: 0;
  background-color: transparent;
}
</style>
