import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import Map from './map';
import Location from './location';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    map: Map,
    location: Location,
  },
  // state: {
  //   location: [10.77963, 106.69901],
  //   zoom: 13,
  //   myLocation: [],
  //   geoJson: Object,
  //   providerChoice: 0,
  //   districtsChoice: [],
  //   // districts: [],
  // },
  // getters: {
  //   getLocation: state => state.location,
  //   getLocationLat: state => state.location.lat,
  //   getLocationLng: state => state.location.lng,
  //   getZoom: state => state.zoom,
  //   getMyLocation: state => state.myLocation,
  //   getGeoJson: state => state.geoJson,
  //   // getProviderChoice: state => state.providerChoice,
  //   // getDistrictsChoice: state => state.districtsChoice,
  // },
  // mutations: {
  //   setLocation: (state, payload) => state.location = payload,
  //   setZoom: (state, payload) => state.zoom = payload,
  //   setMyLocation: (state, payload) => state.myLocation = payload,
  //   setGeoJson: (state, payload) => state.geoJson = payload,
  //   setProviderChoice: (state, payload) => state.providerChoice = payload,
  //   // setDistrictsChoice: (state, payload) => state.districtsChoice = payload,
  // },
  // actions: {
  //   setLocation: ({ commit }, payload) => commit('setLocation', payload),
  //   setZoom: ({ commit }, payload) => commit('setZoom', payload),
  //   setMyLocation: ({ commit }, payload) => {
  //     commit('setMyLocation', payload)
  //     commit('setLocation', payload)
  //   },
  //   setGeoJson: async ({ commit }, url) => {
  //     console.log('setGeoJson', url)
  //     Vue.axios.get(url)
  //       .then(res => commit('setGeoJson', res.data))
  //       .catch(err => console.log(err))
  //   },
  //   // setProviderChoice: ({ commit }, payload) => {
  //   //   commit('setProviderChoice', payload)
  //   //   Vue.axios.get(apiGetDistrict + '?state_id=' + payload)
  //   //     .then(res => {
  //   //       let data = res.data
  //   //       commit('setDistrictsChoice', data.features)
  //   //     })
  //   //     .catch(err => console.log(err));
  //   // },
  // },
  // plugins: [createPersistedState()]
})
