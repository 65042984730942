import { apiGetDistrict, apiGetProvince } from "@/api";
import Vue from "vue";

const Location = {
  name: 'location',
  state: () => ({
    province: [],
    provinceChoice: 0,
    provinceShow: false,
    provinceChoiceName: "Chọn thành phố",

    district: [],
    districtChoice: 0,
    districtShow: false,
    districtChoiceName: "Chọn quận",

    area: [],
    areaChoice: 0,
    areaShow: false,



  }),
  getters: {
    getLocationProvince: state => state.province,
    getLocationProvinceChoice: state => state.provinceChoice,
    getLocationProvinceShow: state => state.provinceShow,

    getLocationDistrict: state => state.district,
    getLocationDistrictChoice: state => state.districtChoice,
    getLocationDistrictShow: state => state.districtShow,

    getLocationArea: state => state.area,
    getLocationAreaChoice: state => state.areaChoice,
    getLocationAreaShow: state => state.areaShow,

    getLocationProvinceChoiceName: state => state.provinceChoiceName,
    getLocationDistrictChoiceName: state => state.districtChoiceName,
  },
  mutations: {
    setLocationProvince: (state, payload) => state.province = payload,
    setLocationProvinceChoice: (state, payload) => state.provinceChoice = payload,


    setLocationDistrict: (state, payload) => state.district = payload,
    setLocationDistrictChoice: (state, payload) => state.districtChoice = payload,


    setLocationArea: (state, payload) => state.area = payload,
    setLocationAreaChoice: (state, payload) => state.areaChoice = payload,

    setLocationProvinceShow: (state, payload) => state.provinceShow = payload,
    setLocationDistrictShow: (state, payload) => state.districtShow = payload,
    setLocationAreaShow: (state, payload) => state.areaShow = payload,

    setLocationProvinceChoiceName: (state, payload) => state.provinceChoiceName = payload,
    setLocationDistrictChoiceName: (state, payload) => state.districtChoiceName = payload,
  },
  actions: {
    setLocationProvince: ({ commit }) => {
      Vue.axios.get(apiGetProvince)
        .then(res => {
          commit('setLocationProvince', res.data.data)
          // console.log('setLocationProvince', res.data.data);
        }).catch(err => console.log(err))
    },
    setLocationProvinceChoice: ({ commit }, payload) => {
      Vue.axios.get(`${apiGetDistrict}?state_id=${payload}`)
        .then(res => {
          commit('setLocationDistrict', res.data.features)

          commit('setLocationProvinceChoice', payload)
          commit('setLocationArea', [])

          commit('setLocationProvinceShow', false)
          commit('setLocationAreaShow', false)
          // console.log('setLocationDistrict', res.data);
        }).catch(err => console.log(err))
    },
    setLocationDistrict: ({ commit }, payload) => commit('setLocationDistrict', payload),
    setLocationDistrictChoice: ({ commit }, payload) => commit('setLocationDistrictChoice', payload),
    setLocationArea: ({ commit }, payload) => commit('setLocationArea', payload),
    setLocationAreaChoice: ({ commit }, payload) => commit('setLocationAreaChoice', payload),

    setLocationProvinceShow: ({ commit }, payload) => commit('setLocationProvinceShow', payload),
    setLocationDistrictShow: ({ commit }, payload) => commit('setLocationDistrictShow', payload),
    setLocationAreaShow: ({ commit }, payload) => commit('setLocationAreaShow', payload),

    setLocationProvinceChoiceName: ({ commit }, payload) => commit('setLocationProvinceChoiceName', payload),
    setLocationDistrictChoiceName: ({ commit }, payload) => commit('setLocationDistrictChoiceName', payload),
  },
}

export default Location;
