import Vue from 'vue'
import {
  BootstrapVue, IconsPlugin, PopoverPlugin, BootstrapVueIcons,
  BIcon, BIconArrowUp, BIconArrowDown,
  BModal, BFormDatalist, BForm,
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(PopoverPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)

Vue.component('BIcon', BIcon)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)
Vue.component('BModal', BModal)
Vue.component('BFormDatalist', BFormDatalist)
Vue.component('b-form', BForm)

