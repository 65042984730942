<template>
  <div class="dropdown-select area-default">
    <div tabindex="0" class="my-select">
      <div class="select">Khu vực</div>
      <div class="dropdown-items" style="display: none"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
