<template>
  <l-marker :lat-lng="location" v-if="dynamicZoom">
    <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor">
      <div class="iconHouse">
        <b-row class="justify-content-md-center" @click="clickLayer()">
          <b-col col align-self="center">
            <img :src="ImgIcon" class="imgHouse" />
          </b-col>
          <b-col align-self="center" class="textHouse">
            {{ price }}
          </b-col>
        </b-row>
      </div>
    </l-icon>
  </l-marker>
</template>

<script>
import ImgIcon from "@/assets/images/Frame.png";


export default {
  props: ['location', 'price', 'zoomAvailable'],
  data() {
    return {
      iconSize: 64,
      ImgIcon: ImgIcon
    }
  },
  mounted() {
    console.log('mounted');
    console.log(this.location.lat);
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1];
    },
    dynamicZoom() {
      return (this.$store.getters['map/getZoom'] >= this.zoomAvailable) ? true : false;
    }
  },
  methods: {
    clickLayer() {
      console.log('Click');
    }
  }
}
</script>

<style scoped>
.iconHouse {
  position: static;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgHouse {
  display: block;
  margin-left: auto;
  /* margin-right: auto; */
}

.textHouse {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  border-radius: 10px;
  color: #fff;
  background-color: #17a2b8;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
}
</style>
