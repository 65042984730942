const apiUrl = 'https://gooodnha.com/api/';

// https://gooodnha.com/api/states/geo_json
const apiGeoJsonAll = 'states/geo_json';

const apiGeoJsonQuan1 = 'region-price/cities?state_id=1'

// https://gooodnha.com/api/state
// const apiGetProvince = 'state';

//danh sach tinh
const apiGetProvince = 'region-price/states';

// danh sach quan huyen
const apiGetDistrict = 'region-price/cities';

// https://gooodnha.com/api/search/all?city_id=1&per_page=1
const apiGetHouse = 'search/all';

const apiGetArea = 'region-price/areas';

export {
  apiUrl,
  apiGeoJsonAll,
  apiGetHouse,
  apiGetProvince,
  apiGetDistrict,
  apiGeoJsonQuan1,
  apiGetArea,
}
